<template>
        <div :class="{ 'form-floating': floating }">
            <textarea id="story" rows="5"
                class="form-control input-grey"
                :class="{
                    'border-radius-left-only': name === 'new-username',
                }"
                :value="inputValue"
                :name="name"
                :type="type"
                :autocomplete="autocomplete"
                :placeholder="placeholder"
                :disabled="disabled"
                @input="onInput"
                @blur="handleBlur"
            >
            </textarea>
        </div>
</template>

<script>
import { useField } from "vee-validate"

export default {
    name: "BasicTextarea",
    props: {
        modelValue: {
            type: String,
            default: "",
        },
        rules: {
            type: Function,
        },
        name: {
            type: String,
            required: true,
        },
        type: {
            type: String,
            default: "text",
        },
        placeholder: {
            type: String,
            default: "",
        },
        autocomplete: {
            type: String,
            default: "",
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        floating: {
            type: Boolean,
            default: false,
        },
        ref: {
            type: String,
            default: "",
        },
        textTransform: {
            type: String,
            default: "",
        },
    },
    emits: ["update:modelValue", "errorMessage"],
    setup(props, { emit }) {
        const {
            value: inputValue,
            errorMessage,
            handleChange,
            handleBlur,
            meta,
        } = useField(props.name, props.rules, {
            initialValue: props.modelValue,
        })

        //For any change in the input, update the modelValue and emit the update:modelValue at the parent component
        const onInput = (event) => {
            let start = event.target.selectionStart
            switch (props.textTransform) {
                case "lowercase":
                    event.target.value = event.target.value.toLowerCase()
                    if (start !== event.target.value.length) {
                        event.currentTarget.setSelectionRange(start, start)
                    }
                    break
                case "uppercase":
                    break
                default:
                    break
            }
            handleChange(event)
            emit("update:modelValue", event.target.value)
        }

        emit("errorMessage", errorMessage)

        return {
            onInput,
            handleChange,
            handleBlur,
            errorMessage,
            inputValue,
            meta,
        }
    },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_loader.scss";
@import "@/assets/scss/variables/fontSize.scss";
.form-control {
    border-radius: 15px;
    padding-top: 14px;
    padding-bottom: 14px;
    font-family: $font_avenir_book;
    font-size: $normal;
    color: $blue-neutral;
}

.input-grey {
    background-color: $light-grey;
    border-color: $light-grey;
}

.input-grey:placeholder {
    color: $grey;
}

.error-message {
    color: $orange;
    font-family: $font_avenir_book;
    font-size: $small;
    width: 100%;
    margin-top: 5px;
}

.form-floating {
    & > label {
        display: flex;
        justify-content: start;
        align-items: center;
        transform-origin: top left !important;
    }
    .form-control-plaintext ~ label,
    .form-control:focus ~ label,
    .form-control:not(:placeholder-shown) ~ label,
    .form-select ~ label {
        color: $blue-neutral;

        &::after {
            background-color: transparent;
        }
    }
}

.border-radius-left-only {
    border-radius: 0px 15px 15px 0px !important;
}
</style>
