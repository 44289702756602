
<script setup>
import { defineComponent } from 'vue';

defineComponent({
    name: "Title"
})
</script>

<template>
    <h1 class="titlee">
        <slot></slot>
    </h1>
</template>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
@import "@/assets/scss/variables/fontSize.scss";

    .titlee{
        font-family: "Avenir Black";
        font-size: 30px;
        margin: 0 !important;    
    }
</style>
